<template>
  <div class="edit-box">
    <el-form
      size="medium"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <!-- 销售总监的内容 -->
      <el-form-item label="跟进方式" prop="type">
        <el-select v-model="ruleForm.type" placeholder="请选择">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="跟进结果" prop="content">
        <el-select v-model="ruleForm.content" placeholder="请选择">
          <el-option
            v-for="item in resultList"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 区域经理的内容 -->
    </el-form>
    <div class="collapseBox" v-if="recordList.length !== 0">
      <el-collapse>
        <el-collapse-item
          :title="item.title"
          :name="index"
          v-for="(item, index) in recordList"
          :key="index"
        >
          <div>
            跟进方式：{{ item.followMode === "1" ? "电话沟通" : "在线沟通" }}
          </div>
          <div>跟进结果：{{ item.followResult }}</div>
          <div>
            家属状态：{{ item.relativesStatus === 0 ? "未处理" : "已知晓" }}
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="sub-btn">
      <el-button size="mini" @click="resetForm('ruleForm')">取消</el-button>
      <el-button size="mini" type="primary" @click="submitForm('ruleForm')"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    recordList: { require: true },
    remindParams: { require: true },
  },
  data() {
    return {
      ruleForm: {
        type: "",
        content: "",
      },
      typeList: [
        {
          label: "电话沟通",
          value: 1,
        },
        {
          label: "在线沟通",
          value: 2,
        },
      ],
      resultList: [
        {
          label: "无法与患者取得联系",
          value: 0,
        },
        {
          label: "已取得联系,患者依旧未完成",
          value: 1,
        },
      ],
      rules: {
        content: [
          { required: true, message: "请选择跟进结果", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择沟通方式", trigger: "blur" }],
      },
    };
  },
  components: {},

  computed: {},

  mounted() {
    console.log(this.recordList);
  },
  created() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateInfo(this.ruleForm);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleChange(val) {
      console.log(val);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$parent.$parent.dialogFormVisibleEditBox = false;
    },
    updateInfo() {
      let params = {
        remindId: this.remindParams.remindId,
        remindType: this.remindParams.remindType,
        followMode: this.ruleForm.type,
        followResult: this.ruleForm.content,
      };
      console.log(params);
      this.$http
        .remindRelations(params)
        .then((res) => {
          console.log(res);
          this.$message.success("提醒成功!");
          this.$emit("updateList");
        })
        .catch((err) => {
          this.$message.error("提醒失败!");
        });
    },
  },
};
</script>
<style scoped>
.sub-btn {
  text-align: center;
}
.edit-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.el-collapse {
  width: 100%;
}
.collapseBox {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 50px;
}
</style>
