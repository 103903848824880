export const callpatients = {
    data() {
        return {
            userInfo: "",
            relationsList: [],
        }
    },
    created() {
        console.log('我被调用了')
    },
    methods: {
        showRelationsDialog(val) {
            console.log(val);
            console.log(this.$store.state.calling.isLogin)
            if(this.$store.state.calling.isLogin){
                let params = {
                    userId: val.userId,
                };
                this.$http.getRelationsPhone(params).then((res) => {
                    console.log(res);
                    this.relationsList = res.result.data;
                    this.relationsDialog = true;
                });
            }else {
                this.$message.warning({
                    message:'请先登录呼叫中心!',
                    duration:1000
                })
            }

        },
        telNum(phone) {
            ClinkAgent.previewOutcall({ tel: phone });
        },

    },
}


