<template>
  <div class="mainBox page-container">
    <Breadcrumb></Breadcrumb>

    <!--  操作区-->
    <el-card>
      <el-form
        :inline="true"
        :model="selectInfo"
        class="demo-form-inline"
        size="small"
        ref="selectOptionsFormRef"
      >
        <el-form-item label="患者信息" prop="keyword">
          <el-input
            v-model="selectInfo.keyword"
            placeholder="请输入患者姓名/手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="医院" prop="hospitalId">
          <el-select
            v-model="selectInfo.hospitalId"
            placeholder="选择医院"
            filterable
            remote
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in hospitalList"
              :key="index"
              :label="item.hospitalName"
              :value="item.hospitalId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="医生工作室" prop="groupId">
          <el-select
            v-model="selectInfo.groupId"
            placeholder="选择工作室"
            filterable
            remote
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in doctorOfficeList"
              :key="index"
              :label="item.groupName"
              :value="item.groupId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联医助" prop="assistantId">
          <el-select
            v-model="selectInfo.assistantId"
            placeholder="选择工作室"
            filterable
            remote
          >
            <el-option label="全部" :value="''"></el-option>
            <el-option
              v-for="(item, index) in assistantList"
              :key="index"
              :label="item.assistantName"
              :value="item.assistantId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="复查开始时间"
          prop="reviewBeginRange"
          v-show="activeName === '1'"
        >
          <el-date-picker
            v-model="selectInfo.reviewBeginRange"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="复查结束时间"
          prop="reviewFinishRange"
          v-show="activeName === '2'"
        >
          <el-date-picker
            v-model="selectInfo.reviewFinishRange"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="resetSelectOptions"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <div class="formregion">
      <el-tabs @tab-click="handleClick" v-model="activeName" type="card">
        <el-tab-pane label="待处理" name="1">
          <el-table :data="patientList" border style="width: 100%" size="mini">
            <el-table-column
              type="index"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="userName"
              label="患者姓名"
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="phoneNo"
              label="手机号码"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="duration"
              label="入组时长"
              align="center"
              width="80"
            >
              <template slot-scope="socpe">
                <span v-if="!socpe.row.duration">0天</span>
                <span v-else>{{ socpe.row.duration }}天</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="hospitalName"
              label="医院名称"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="assistantName"
              label="关联医助"
              width="120"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="groupName"
              label="医生工作室"
              align="center"
              width="120"
            >
              <template slot-scope="socpe">
                <span v-if="socpe.row.groupName == null">-</span>
                <span v-else>{{ socpe.row.groupName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="times"
              label="本次复查次数"
              align="center"
              width="100"
            >
              <template slot-scope="socpe">
                <span v-if="socpe.row.times === 0">个性化复查</span>
                <span v-else>{{ `第${socpe.row.times}次复查` }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="复查开始时间" align="center">
              <template slot-scope="socpe">{{
                socpe.row.date | dateFormat("YYYY-MM-DD HH:mm")
              }}</template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="复查进度"
              align="center"
              width="100"
            >
              <template slot-scope="socpe">
                <!--                <span v-if="!socpe.row.wakeNum">&#45;&#45;</span>-->
                <span
                  >复查{{ socpe.row.wakeNum ? socpe.row.wakeNum : "0" }}次</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="overview" label="概述" align="center">
              <template slot-scope="scope">
                <el-popover
                  placement="top-start"
                  title="概述"
                  width="300"
                  trigger="hover"
                >
                  <div v-html="scope.row.overview"></div>
                  <span slot="reference">{{
                    scope.row.overview | filterHtml
                  }}</span>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              prop="vipstate"
              width="100"
              label="操作列"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  @click="toReviewdetails(scope.row, 0)"
                  type="text"
                  size="small"
                  >复查</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已处理" name="2">
          <el-table
            :data="dealedPatientList"
            border
            style="width: 100%"
            size="mini"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="userName"
              label="患者姓名"
              width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="phoneNo"
              label="手机号码"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="duration"
              label="入组时长"
              align="center"
              width="80"
            >
              <template slot-scope="socpe">
                <span v-if="!socpe.row.duration">0天</span>
                <span v-else>{{ socpe.row.duration }}天</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="hospitalName"
              label="医院名称"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="assistantName"
              label="关联医助"
              width="120"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="groupName"
              label="医生工作室"
              align="center"
              width="120"
            >
              <template slot-scope="socpe">
                <span v-if="socpe.row.groupName == null">-</span>
                <span v-else>{{ socpe.row.groupName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="times"
              label="本次复查次数"
              align="center"
              width="100"
            >
              <template slot-scope="socpe">
                <span v-if="socpe.row.times === 0">个性化复查</span>
                <span v-else>{{ `第${socpe.row.times}次复查` }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="复查开始时间" align="center">
              <template slot-scope="socpe">
                <span v-if="socpe.row.date">
                  {{ socpe.row.date | dateFormat("YYYY-MM-DD HH:mm") }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="completeTime"
              label="复查结束时间"
              align="center"
            >
              <template slot-scope="socpe">
                <span v-if="socpe.row.completeTime">{{
                  socpe.row.completeTime | dateFormat("YYYY-MM-DD HH:mm")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="复查进度"
              align="center"
              width="100"
            >
              <template slot-scope="socpe">
                <!--                <span v-if="!socpe.row.wakeNum">&#45;&#45;</span>-->
                <span
                  >复查{{
                    socpe.row.progress ? socpe.row.progress : "0"
                  }}次</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="overview" label="概述" align="center">
              <template slot-scope="scope">
                <el-popover
                  placement="top-start"
                  title="概述"
                  width="300"
                  trigger="hover"
                >
                  <div v-html="scope.row.overview"></div>
                  <span slot="reference">{{
                    scope.row.overview | filterHtml
                  }}</span>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              prop="vipstate"
              width="150"
              label="操作列"
              align="center"
            >
              <template slot-scope="scope">
                <!--                <el-tooltip-->
                <!--                    class="item"-->
                <!--                    effect="dark"-->
                <!--                    content="提醒患者填写问卷/上传检查"-->
                <!--                    placement="bottom"-->
                <!--                    :open-delay="500"-->
                <!--                >-->
                <!--                  <el-button-->
                <!--                      @click="alertPatient(scope.row)"-->
                <!--                      type="text"-->
                <!--                      size="small"-->
                <!--                  >提醒患者</el-button-->
                <!--                  >-->
                <!--                </el-tooltip>-->
                <!--                <el-button-->
                <!--                    @click="goAddOrder(scope.row)"-->
                <!--                    type="text"-->
                <!--                    size="small"-->
                <!--                >添加工单</el-button-->
                <!--                >-->
                <el-button
                  @click="toReviewdetails(scope.row, 1)"
                  type="text"
                  size="small"
                  >复查详情</el-button
                >
                <!--                <el-button-->
                <!--                    @click="toFillQuestionnaire(scope.row)"-->
                <!--                    type="text"-->
                <!--                    size="small"-->
                <!--                    v-if="scope.row.status == 4"-->
                <!--                >填写问卷</el-button-->
                <!--                >-->
                <!--                <el-button-->
                <!--                    @click="remindRelations(scope.row)"-->
                <!--                    type="text"-->
                <!--                    size="small"-->
                <!--                >提醒家属</el-button-->
                <!--                >-->
                <el-button
                  @click="showRelationsDialog(scope.row)"
                  type="text"
                  size="small"
                  >呼叫</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div class="pag_warpper">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagenum"
          :page-sizes="[10, 20, 50]"
          :page-size="pagesize"
          layout="slot, sizes, prev, pager, next, jumper"
          :total="total"
          style="margin-left: 30px"
        >
          <span class="total"
            >共 {{ total }} 条数据，共
            {{ Math.ceil(total / pagesize) }} 页</span
          >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogFormVisibleEditBox"
      width="600px"
      title="复查提醒"
    >
      <EditInfo
        v-if="dialogFormVisibleEditBox"
        :recordList="recordList"
        :remindParams="remindParams"
        @updateList="updateList"
      ></EditInfo>
      <!--      :groupIdArr="groupIdArr"-->
    </el-dialog>
    <el-dialog
      :visible.sync="relationsDialog"
      width="360px"
      :show-close="false"
      :modal="false"
      class="callAndUnbundleBox"
    >
      <div class="callAndUnbundleCon">
        <div class="title" v-for="(item, index) in relationsList" :key="index">
          <div>{{ item.name }} :{{ item.phone }}</div>
          <span @click="telNum(item.phone)" style="cursor: pointer">呼叫</span>
        </div>
        <div class="associatedBtn">
          <div class="associatedCancel" @click="relationsDialog = false">
            取消
          </div>
          <!--          <div class="associatedSure" @click="callAndUnbundleSure()">确定</div>-->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { timeMode } from "../../tools/utils";
import EditInfo from "./components/EditInfo.vue";
import { selectparams } from "../../mixins/selectparams";
import { callpatients } from "../../mixins/callpatients";

export default {
  name: "reviewManagement",
  mixins: [selectparams, callpatients],
  components: {
    EditInfo,
  },
  data() {
    return {
      timeMode,
      dialogFormVisibleEditBox: false,
      //提醒参数
      remindParams: {},
      //提醒记录
      recordList: [],
      //添加工单管理对话框
      relationsDialog: false,
      //总条数
      total: 0,
      doctorOfficeList: [],
      assistantList: [],
      hospitalList: [],
      value: "",
      //查询数据
      selectInfo: {
        keyword: "",
        groupId: "",
        hospitalId: "",
        assistantId: "",
        reviewBeginRange: [],
        reviewFinishRange: [],
      },
      pagenum: 1,
      pagesize: 10,
      activeType: "1",
      //待分配列表患者
      toassignePatients: [],
      //已分配患者列表
      patientList: [],
      dealedPatientList: [],
      //当前操作患者信息
      currentPatientInfo: {},
      activeName: "1",
    };
  },
  created() {},
  beforeRouteLeave(to, from, next) {
    console.log(to.path, "==========================");
    if (to.path === "/taskcenter") {
      sessionStorage.removeItem("selectParams");
      sessionStorage.removeItem("actionType");
    }
    next();
  },
  //总条数
  computed: {},
  mounted() {
    console.log(
      JSON.parse(sessionStorage.getItem("selectParams")),
      "==============缓存参数==============="
    );
    console.log(
      sessionStorage.getItem("actionType"),
      "==============缓存参数==============="
    );
    if (JSON.parse(sessionStorage.getItem("selectParams"))) {
      this.selectInfo = JSON.parse(sessionStorage.getItem("selectParams"));
    }
    if (sessionStorage.getItem("actionType")) {
      this.activeType = sessionStorage.getItem("actionType");
    }
    this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
  },
  methods: {
    //重置筛选项
    resetSelectOptions() {
      console.log("重置筛选项");
      this.$refs.selectOptionsFormRef.resetFields();
      this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
    },
    //查询按钮
    async onSubmit() {
      console.log(this.selectInfo, "查询参数============");
      this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
    },
    //获取患者数据默认列表
    getDefalutPatientList(selectInfo, page, pageSize) {
      let _params = {
        customerServerId: this.userInfo.customerServerId,
        keyword: selectInfo.keyword,
        groupId: selectInfo.groupId,
        hospitalId: selectInfo.hospitalId,
        assistantId: selectInfo.assistantId,
        reviewBeginTimeStart: selectInfo.reviewBeginRange[0]
          ? selectInfo.reviewBeginRange[0]
          : "",
        reviewBeginTimeEnd: selectInfo.reviewBeginRange[1]
          ? selectInfo.reviewBeginRange[1]
          : "",
        reviewFinishTimeStart: selectInfo.reviewFinishRange[0]
          ? selectInfo.reviewFinishRange[0]
          : "",
        reviewFinishTimeEnd: selectInfo.reviewFinishRange[1]
          ? selectInfo.reviewFinishRange[1]
          : "",
        page,
        pageSize,
      };
      console.log(_params);
      if (this.activeName == "1") {
        this.getPatientsList(_params);
      } else if (this.activeName == "2") {
        this.getDealedPatients(_params);
      }
    },
    //获取待处理患者数据
    getPatientsList(params) {
      console.log(params, "===========打印列表参数=============");
      this.$http.getTaskcenterReviewList(params).then((res) => {
        console.log(res);
        this.pagesize = res.result.data.rows;
        this.total = res.result.data.records ? res.result.data.records : 0;
        this.pagenum = res.result.data.page;
        this.patientList = res.result.data.data;
      });
    },
    //获取已处理患者数据
    getDealedPatients(params) {
      console.log(params, "===========打印列表参数=============");
      this.$http.getDealedTaskcenterReviewList(params).then((res) => {
        console.log(res);
        this.pagesize = res.result.data.rows;
        this.total = res.result.data.records ? res.result.data.records : 0;
        this.pagenum = res.result.data.page;
        this.dealedPatientList = res.result.data.data;
      });
    },
    handleClick() {
      //获取默认数据列表
      this.pagenum = 1;
      this.pagesize = 10;
      this.$refs.selectOptionsFormRef.resetFields();
      this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
    },
    //提醒患者
    alertPatient(row) {
      this.debounceAlertPatient(row);
    },
    //提醒患者防抖
    debounceAlertPatient(row) {
      console.log(row);
      let _params = {
        reviewId: row.reviewId,
        status: row.status,
      };
      //提醒患者请求(后续再测)
      this.$http
        .remindToReview(_params)
        .then((res) => {
          console.log(
            res,
            "===========每次提醒需间隔4个小时============提醒患者"
          );
          if (res.result.code == 1) {
            this.$message.success("提醒成功!");
          }
        })
        .catch((err) => {
          if (
            err.response.data.message.indexOf("每次提醒需间隔4个小时") != -1
          ) {
            this.$message.error("每四个小时只能提醒一次!");
          }
        });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
    },
    handleCurrentChange(val) {
      this.pagenum = val;
      this.getDefalutPatientList(this.selectInfo, this.pagenum, this.pagesize);
    },
    //添加工单对话框打开
    goAddOrder(value) {
      this.addOrderDialogVisiable = true;
      this.addOrderForm.name = value.name;
      this.addOrderForm.office = value.groupName;
      this.addOrderForm.assistantId = value.assistantId;
      this.currentPatientInfo = value;
    },
    toReviewdetails(info, type) {
      sessionStorage.setItem("selectParams", JSON.stringify(this.selectInfo));
      sessionStorage.setItem("actionType", this.activeType);
      this.$router.push({ name: "reviewdetails", query: { ...info, type } });
    },
    //父组件更新信息
    updateList() {
      this.dialogFormVisibleEditBox = false;
    },
  },
};
</script>

<style scoped lang="less">
.mainBox {
  display: flex;
  flex-direction: column;
  /*height: 100%*/
}
/*.el-form {*/
/*  margin-left: 20px;*/
/*}*/
.el-form-item {
  margin-right: 30px;
}
/*.el-tabs {*/
/*  margin-left: 30px;*/
/*}*/
.el-tab-pane {
  max-height: 560px;
  overflow-y: auto;
}
/deep/.el-table {
  margin-top: 0;
}
.formregion {
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 10px 10px 10px 0;
  flex: 1;
  box-sizing: border-box;
  padding: 16px 20px;
}
.pag_warpper {
  background-color: #ffffff;
  height: 80px;
  padding: 20px;
  border-radius: 0 0 0 10px;
}
.el-card {
  flex-shrink: 0;
}
.total {
  margin-right: 24px;
  font-weight: 400 !important;
  color: #606266;
}
/deep/.callAndUnbundleBox {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 30px;
    box-sizing: border-box;
    .callAndUnbundleCon {
      .title {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #606266;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 8px;
        span {
          color: #409eff;
        }
      }
      .associatedBtn {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        .associatedCancel {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303133;
          width: 120px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #dcdfe6;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .associatedSure {
          width: 120px;
          height: 40px;
          background: #4facff;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
