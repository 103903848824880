export const selectparams = {
    data() {
        return {
            doctorOfficeList: [],
            assistantList: [],
            hospitalList: [],
            userInfo: "",
        }
    },
    created() {
        this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
        this.getOfficeList();
        this.getAssistantName();
        this.getHospitalName();
    },
    methods: {
        getOfficeList(){
            this.$http.getGroupNameFromCustomer({customerServerId:this.userInfo.customerServerId}).then(res=>{
                console.log(res.result.data,'========doctorOfficeList=======')

                this.doctorOfficeList = res.result.data;
            })
        },
        //获取关联医助
        getAssistantName(){
            this.$http.getAssistantName({customerServerId:this.userInfo.customerServerId}).then(res=>{
                console.log(res,'========获取关联医助=======')
                this.assistantList = res.result.data;
            })
        },
        //获取医院
        getHospitalName(){
            this.$http.getHospitalName({customerServerId:this.userInfo.customerServerId}).then(res=>{
                console.log(res,'========获取医院姓名=======')
                this.hospitalList = res.result.data;
            })
        },
    },
}


